:root {
  font-size: 16px;
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@0,200;1,600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');



//! MAIN TITLE HEADER 
.portfolio-title-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 3rem;
  @media screen and (max-width: 770px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 415px) {
    margin: 0 6rem;
  }
  .portfolio-title {
    font-family: 'Cardo', serif;
    font-weight: 700;
    margin-bottom: 0;
  }
}

//! WRAPPER
.about-project-one-wrapper {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) { 
    flex-direction: column !important;
    align-items: center;
  }
}


//! NAVBAR
.navbar {
  display: flex;
  justify-content: center;
  margin: 0.6rem; 
  font-size: 1.2rem;
  font-family: 'Spectral SC', serif;
  font-weight: bold;
  .navbar-item {
    text-decoration: none;
    margin: 0 35px 0 5px;
    @media screen and (max-width: 415px) { 
      margin: 0px 5px 0 5px;
    }
  }
  .navbar-item:hover {
    font-style: italic;
    font-weight: bold;
  } 
  a {
    color: black;
  }

  }

//! Footer
.copy {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 100;
  color: grey;
}

.footer {
  flex-direction: column;
}

//! HOME & ABOUT
.about-wrap {
  margin: 0 5rem 0 10rem;
  @media screen and (max-width: 770px) { 
    margin: auto !important;
  }
  @media screen and (max-width: 1024px) { 
    margin: 5rem;
  }
}

.home-about-wrap {
  margin: 1rem;
  @media screen and (max-width: 770px) { 
    display: flex;
    justify-content: center;
  }
  .about-h1 {
    margin: 0;
    font-family: 'Lobster', cursive;
    padding: 0 2rem;
  }
}

.about-page-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 80vh;
}

.home-about-text-wrap {
  margin: 10px;
  max-width: 35rem;
  // font-family: 'Spectral SC', serif;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
  @media screen and (max-width: 1024px) { 
    max-width: 50rem;
  }
  @media screen and (max-width: 770px) { 
    margin: 3rem;
  }
}

.about-h1 {
  font-size: 3rem;
  font-style: italic;
}

.about-page-text-wrap {
  max-width: 50rem;
  font-family: 'Spectral SC', serif;
  @media screen and (max-width: 415px) { 
    max-width: 20rem !important;
  }
  @media screen and (max-width: 770px) { 
    max-width: 40rem;
  }
}

//! PROJECTS
.projects-images-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 770px) { 
    display: flex;
    flex-direction: column;
  }
}

.projects-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.project-image {
    margin: 1rem;
    max-width: 20rem;
    // max-height: 20rem;
    box-shadow: 20px 5px 30px #aaaaaa;
}

.project-four-image {
  margin: 1rem;
  max-width: 25rem;
  box-shadow: 20px 5px 30px #aaaaaa;
  @media screen and (max-width: 415px) { 
    max-width: 20rem;
  }
}

//! PROJECT ONE
.project-one-wrap {
  display: flex;
  // margin-bottom: 7rem;
  @media screen and (max-width: 415px) { 
    flex-direction: column;
    max-width: 22.5rem;
    margin:auto;
  }
  @media screen and (max-width: 1024px) { 
    justify-content: center;
    margin: 0 3rem;
    flex-wrap: wrap;
  }
}
.project-one-column-one {
  display: flex;
  flex-direction: column;
}
.project-one-column-two {
  display: flex;
  flex-direction: column;
}

.portfolio-project-one-h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  a {
    text-decoration: none;
    color: white;
  }
}

.project-one-h3-big-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  a {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 415px) { 
    display: none !important;
  }
  @media screen and (max-width: 830px) { 
    display: none !important;
  }
  @media screen and (min-width: 1023px) { 
    display: flex;
  }
}

.project-one-h3-small-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  a {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 415px) { 
    display:flex !important;
  }
  @media screen and (max-width: 770px) { 
    display:flex !important;
  }
  @media screen and (min-width: 831px) { 
    display:none !important;
  }
}
//! //! //! //! //!

.project-all-apart-from-one-h3{
  a {
  text-decoration: none;
  color: white;
  }
}

.project-title-project-one {
  justify-content: center;
  margin: 2.5rem 1rem 1rem;
  font-size: 3rem;
  font-family: 'Cardo', serif;
  @media screen and (max-width: 415px) { 
    margin: 2.5rem 2rem .5rem;
    font-size: 2.5rem;
    font-family: 'Cardo', serif;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 1024px) {
    display:flex;
    margin: 2.5rem;
  }
}

.project-title-project-four {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: 'Cardo', serif;
  margin-top: 4rem;
  @media screen and (max-width: 415px) { 
    margin: 2.5rem 2rem .5rem;
    font-size: 2.5rem;
    font-family: 'Cardo', serif;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
  }
}

.project-title-project-three {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: 'Cardo', serif;
  margin-top: 4rem;
  @media screen and (max-width: 415px) { 
    margin-bottom: 0.5rem;
    }
}

.project-title-project-two {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: 'Cardo', serif;
  margin-top: 4rem;
  @media screen and (max-width: 415px) { 
    margin-bottom: 0.5rem;
    }
}

.project-four-section-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 415px) { 
    // margin: 2.5rem;
    margin: 0
    }
}
.project-four-column-one {
  display: flex;
  flex-direction: column;
}
.project-four-column-two {
  display: flex;
  flex-direction: column;
}
.project-four-column-three {
  display: flex;
  flex-direction: column;
}

.project-three-section-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.project-three-column-one {
  display: flex;
  flex-direction: column;
}
.project-three-column-two {
  display: flex;
  flex-direction: column;
}


.projects-header-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

h3 {
  display: flex;
  justify-content: center;
  font-family: 'Spectral SC', serif;
  font-size: 1rem;
}

.h3-portfolio-page-plntify {
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
}
.h3-portfolio-page-sorting-hat {
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
}

.project-four-lang {
  max-width: 20rem;
  margin-top: 0.5rem;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  @media screen and (max-width: 770px) { 
    margin: 1rem;
  }
}

.sorting-hat-lang {
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  max-width: 20rem;
}

.plntify-lang {
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 0.8rem;
  max-width: 20rem;
}

.project-two-section-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 415px) { 
    margin: 0 5rem;
  }
}

.project-three-and-two-wrap {
  display: flex;
  flex-direction: column;
}

.projects-wrap {
  margin-right: 10em;
  @media screen and (max-width: 770px) { 
    margin-right: 0 !important;
  }
  @media screen and (max-width: 1025px) { 
    margin-right: 0 !important;
  }
}

.projects-page-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 415px) { 
    margin:auto;
  }
}

.projects-h1 {
  font-size: 5rem;
  font-family: 'Lobster', cursive;
  font-style: italic;
  margin-top: 3rem;
  margin-bottom: 0;
  padding: 0 2rem;
}

.flareon-about-app-text-wrap {
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
  margin: 2rem;
  @media screen and (max-width: 420px) { 
    width: 18rem;
  }
  @media screen and (max-width: 770px) { 
    margin: auto;
    max-width: 40rem !important;
  }
  @media screen and (max-width: 1024px) { 
    max-width: 50rem;
  }
}

.plntify-about-app-text-wrap {
  max-width: 25rem;
  margin: 3rem;
  margin-top: 0;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
  @media screen and (max-width: 1024px) { 
    max-width: 50rem;
  }
}
.sorting-hat-about-app-text-wrap {
  max-width: 42rem;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
}
.fam-about-app-text-wrap {
  max-width: 20rem;
  margin: 3rem;
  margin-top: 0;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
  @media screen and (max-width: 1024px) { 
    max-width: 50rem;
  }
}
.photography-text-wrap {
  max-width: 50rem;
  margin: 3rem;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Courier, Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  font-size: 1rem;
  @media screen and (max-width: 1024px) { 
    max-width: 50rem;
  }
}

//! PORTFOLIO PAGE 
.portfolio-page-wrap {
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  @media screen and (max-width: 415px) { 
    margin: auto;
  }
  @media screen and (max-width: 1024px) { 
    margin: auto;
  }
}

.portfolio-page-project-title-project-one {
  display: flex;
  justify-content: center;
  font-size: 3rem;
}

.portfolio-page-projects-h1 {
  font-size: 5rem;
  font-family: 'Lobster', cursive;
  font-style: italic;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 2rem;
  @media screen and (max-width: 415px) { 
    display: flex;
    // margin-right: 3rem !important;
    margin:auto;
  }
  @media screen and (max-width: 1025px) { 
    // margin-right: 10rem;
    margin: auto;
  }
}

//! PHOTORAPHY
.photography-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photography-header-wrap {
  display: flex;
  flex-direction: column;
}

.slide-image {
  max-width: 25rem;
  justify-content: center;
  @media screen and (max-width: 415px) { 
    max-width: 13rem !important;
  }
}

.photography-h1 {
  font-size: 4rem;
  font-family: 'Lobster', cursive;
  font-style: italic;
  margin-top: 4rem;
  // margin-bottom: 0.3rem;
  margin-bottom:0;
  padding: 0 5rem;
  @media screen and (max-width: 415px) { 
    font-size: 3rem;
  }
}


//! CONTACT 
.contact-section {
  // height: 60vh;
}

.contact-wrap {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 3rem;
  h1 {
    margin: auto;
  }
}

.contact-text-wrap {
  justify-content: center;
  display: flex;
  font-size: 1.5rem;
}

.contact-h1 {
  font-size: 3rem;
  font-family: 'Lobster', cursive;
  font-style: italic;
  padding: 0 2rem;
}

//! contact page
.contact-button {
  position: relative;
  width: 15rem !important;
  color: white !important;
  height: 50px;
  margin: auto !important;
  margin-bottom: 0.5rem;
  margin-top: 2rem !important;
  overflow: hidden;
  border: 1px solid black !important;
  border-radius: 3rem;
  font-family: 'Lobster', cursive;
  font-weight: 300;
  font-size: 1rem;
  transition: 0.5s;
  letter-spacing: 1px;
  box-shadow: 0px 5px 10px #aaaaaa;
}
.email-link {
  color: white !important;
  text-decoration: none !important;
}
.email-wrap {
  margin-bottom: 3rem;
}

.contact-github-icon-wrap {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  .contact-github-icon {
    max-width: 5rem;
    margin-left: 1rem;
  }
}

//! TECH ICONS
.javascript-icon {
  margin-bottom: 0 !important;
}
.javascript-h1 {
  margin: 0;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
}

.node-js-icon {
  margin-bottom: 0 !important;
}
.node-js-h1 {
  margin: 0;
  font-size: 0.8rem;
  color: green;
  display: flex;
  justify-content: center;
}

//! TECH ICONS FOR EACH PROJECT
.javascript-icon-project {
  margin-top: 0 !important;
}


//! SLIDESHOW

.slide-container {
  max-width: 33rem;
  margin: auto; 
  @media screen and (max-width: 415px) { 
    max-width: 20rem !important;
  }
}
  
div.react-slideshow-container .nav:first-of-type {
  margin-right: 2.5rem ;
  @media screen and (max-width: 415px) { 
    margin-right: 2rem !important;
  }
}

.react-slideshow-container {
  max-height: 80rem;
}

.indicators {
  margin: 0;
}

.react-slideshow-container + div.indicators {
  display: none !important;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.slideshow-h2 {
  font-size: 1.2rem;
  font-family: 'Cardo', serif;
  font-weight: bold;
}



//! icons 
.tech-icon-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 770px) { 
    // margin: 3rem;
    margin-bottom: 0;
  }
}

.icon {
  width: 3.5rem;
  margin: 0.5rem;
  @media screen and (max-width: 770px) { 
    height: 3rem;
  }
}

.icon2 {
  width: 2.5rem;
  margin: 0.5rem;
  margin-bottom: 0 !important;
  @media screen and (max-width: 770px) { 
    height: 3rem;
  }
}

.icon3 {
  width: 4.5rem;
  margin: 0.5rem;
  @media screen and (max-width: 770px) { 
    height: 3rem;
  }
}

.tech-icons-wrap-about-page {
  margin-top: 5rem;
}




//! link button animation
.link-buttons-project-all-apart-from-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 0.5rem;
}

.link-buttons-wrap{
  display: flex;
}    


//! starts here
* {
  // margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
  
  @mixin button($bcolor,
  $url,
  $x1,
  $y1,
  $bor,
  $col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
  }
  
  
.button-container-1 {
  position: relative;
  width: 8rem;
  height: 50px;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  border: 1px solid;
  border-radius: 3rem;
  font-family: 'Lobster', cursive;
  font-weight: 300;
  font-size: 1rem;
  transition: 0.5s;
  letter-spacing: 1px;
  box-shadow: 0px 5px 10px #aaaaaa;

    
  button {
    width: 101%;
    height: 100%;
    font-family: 'Lobster', cursive;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 1px;
    @include button(#000,
    "https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/nature-sprite.png",
    2300%,
    100%,
    none,
    #fff);
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(22) forwards;
    animation: ani2 0.7s steps(22) forwards;
    
    &:hover {
    -webkit-animation: ani 0.7s steps(22) forwards;
    animation: ani 0.7s steps(22) forwards;
    }
  }
}
  

.mas {
  position: absolute;
  color: #000;
  text-align: center;
  width: 100%;
  font-weight: 300;
  position: absolute;
  font-size: 1rem;
  margin-top: 0.8rem;
  overflow: hidden;
}

@-webkit-keyframes ani {
  from {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  }

  to {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  }

  to {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
  }
}

